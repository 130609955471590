import { InputCaptionTheme } from '@/components/InputCaption/theme';
import { tw } from '@/utils/tw';

const Caption = tw.theme({
  extend: InputCaptionTheme,
});

const CheckboxTheme = tw.theme({
  slots: {
    base: ['group', 'gap-2', 'relative', 'z-1', 'flex', 'flex-wrap'],
    label: ['text-body', 'text-black', 'flex', 'items-center', 'gap-x-2'],
    input: [
      'appearance-none',
      'bg-white',
      'cursor-pointer',
      'h-5',
      'p-1.25',
      'relative',
      'shadow-gray-900',
      'shadow-inset-1',
      'transition-all',
      'w-5',

      'after:-translate-x-1/2',
      'after:-translate-y-2/3',
      'after:absolute',
      'after:block',
      'after:border-b-2',
      'after:border-gray-900',
      'after:border-l-2',
      'after:h-1.25',
      'after:left-1/2',
      'after:origin-center',
      'after:scale-0',
      'after:top-1/2',
      'after:transition-all',
      'after:w-2.5',

      'before:-translate-x-1/2',
      'before:-translate-y-1/2',
      'before:-z-2',
      'before:absolute',
      'before:bg-primary-200',
      'before:block',
      'before:h-[170%]',
      'before:left-1/2',
      'before:rounded-full',
      'before:scale-0',
      'before:top-1/2',
      'before:transition-all',
      'before:w-[170%]',

      'checked:after:-rotate-45',
      'checked:after:scale-100',

      'hover:before:scale-100',
      'hover:shadow-black',
      'hover:shadow-inset-2',

      'focus:before:scale-100',
      'focus:shadow-inset-2',
      'focus:shadow-primary-700',

      'active:before:scale-0',
      'active:bg-gray-100',
      'active:shadow-gray-900',
      'active:shadow-inset-1',
    ],
  },
  variants: {
    variant: {
      default: {},
      alternative: {
        base: ['relative'],
        input: [
          'active:bg-primary-100',
          'active:outline-0',
          'active:shadow-md',
          'after:-translate-x-1/2',
          'after:-translate-y-1/2',
          'after:absolute',
          'after:bg-black',
          'after:block',
          'after:h-2/3',
          'after:left-1/2',
          'after:origin-bottom',
          'after:rounded-full',
          'after:top-1/2',
          'after:transition-all',
          'after:w-0.5',
          'appearance-none',
          'before:-translate-x-1/2',
          'before:-translate-y-1/2',
          'before:absolute',
          'before:bg-black',
          'before:block',
          'before:h-0.5',
          'before:left-1/2',
          'before:origin-left',
          'before:rounded-full',
          'before:top-1/2',
          'before:transition-all',
          'before:w-2/3',
          'bg-white',
          'checked:active:bg-primary-800',
          'checked:after:bg-white',
          'checked:after:h-1/2',
          'checked:after:left-2/5',
          'checked:after:rotate-45',
          'checked:after:top-2/5',
          'checked:after:translate-x-0',
          'checked:before:bg-white',
          'checked:before:left-2/5',
          'checked:before:rotate-45',
          'checked:before:top-2/5',
          'checked:before:translate-y-0',
          'checked:before:w-1/3',
          'checked:bg-primary-800',
          'checked:focus:bg-primary-800',
          'checked:focus:shadow-primary-400',
          'checked:hover:bg-primary-800',
          'checked:shadow-inset-0',
          'cursor-pointer',
          'focus:outline-6',
          'focus:shadow-inset-2',
          'focus:shadow-primary-700',
          'h-9',
          'hover:outline-6',
          'hover:shadow-inset-0',
          'hover:shadow-primary-700',
          'outline-0',
          'outline-primary-200',
          'outline',
          'relative',
          'rounded-full',
          'shadow-md',
          'transition-all',
          'w-9',
        ],
      },
    },
  },
});

export default Object.assign(CheckboxTheme, { Caption });
